// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-auth-signin-tsx": () => import("./../../../src/pages/auth/signin.tsx" /* webpackChunkName: "component---src-pages-auth-signin-tsx" */),
  "component---src-pages-auth-signout-tsx": () => import("./../../../src/pages/auth/signout.tsx" /* webpackChunkName: "component---src-pages-auth-signout-tsx" */),
  "component---src-pages-auth-signup-tsx": () => import("./../../../src/pages/auth/signup.tsx" /* webpackChunkName: "component---src-pages-auth-signup-tsx" */),
  "component---src-pages-blog-general-investment-march-mdx": () => import("./../../../src/pages/blog/general/investment-march.mdx" /* webpackChunkName: "component---src-pages-blog-general-investment-march-mdx" */),
  "component---src-pages-blog-stt-a-journey-to-10-word-error-rate-mdx": () => import("./../../../src/pages/blog/stt/a-journey-to-10-word-error-rate.mdx" /* webpackChunkName: "component---src-pages-blog-stt-a-journey-to-10-word-error-rate-mdx" */),
  "component---src-pages-blog-stt-deepspeech-0-6-speech-to-text-engine-mdx": () => import("./../../../src/pages/blog/stt/deepspeech-0-6-speech-to-text-engine.mdx" /* webpackChunkName: "component---src-pages-blog-stt-deepspeech-0-6-speech-to-text-engine-mdx" */),
  "component---src-pages-blog-stt-speech-recognition-deepspeech-mdx": () => import("./../../../src/pages/blog/stt/speech-recognition-deepspeech.mdx" /* webpackChunkName: "component---src-pages-blog-stt-speech-recognition-deepspeech-mdx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-blog-tts-african-languages-dataset-collaboration-mdx": () => import("./../../../src/pages/blog/tts/african-languages-dataset-collaboration.mdx" /* webpackChunkName: "component---src-pages-blog-tts-african-languages-dataset-collaboration-mdx" */),
  "component---src-pages-blog-tts-coqui-studio-feb-2023-release-mdx": () => import("./../../../src/pages/blog/tts/coqui-studio-feb-2023-release.mdx" /* webpackChunkName: "component---src-pages-blog-tts-coqui-studio-feb-2023-release-mdx" */),
  "component---src-pages-blog-tts-coqui-the-polyglot-mdx": () => import("./../../../src/pages/blog/tts/coqui_the_polyglot.mdx" /* webpackChunkName: "component---src-pages-blog-tts-coqui-the-polyglot-mdx" */),
  "component---src-pages-blog-tts-cpml-mdx": () => import("./../../../src/pages/blog/tts/cpml.mdx" /* webpackChunkName: "component---src-pages-blog-tts-cpml-mdx" */),
  "component---src-pages-blog-tts-dive-deeper-into-the-world-of-xtts-mdx": () => import("./../../../src/pages/blog/tts/dive_deeper_into_the_world_of_xtts.mdx" /* webpackChunkName: "component---src-pages-blog-tts-dive-deeper-into-the-world-of-xtts-mdx" */),
  "component---src-pages-blog-tts-gradual-training-with-tacotron-for-faster-convergence-mdx": () => import("./../../../src/pages/blog/tts/gradual-training-with-tacotron-for-faster-convergence.mdx" /* webpackChunkName: "component---src-pages-blog-tts-gradual-training-with-tacotron-for-faster-convergence-mdx" */),
  "component---src-pages-blog-tts-introducing-xtts-for-game-studios-mdx": () => import("./../../../src/pages/blog/tts/introducing_xtts_for_game_studios.mdx" /* webpackChunkName: "component---src-pages-blog-tts-introducing-xtts-for-game-studios-mdx" */),
  "component---src-pages-blog-tts-open-xtts-mdx": () => import("./../../../src/pages/blog/tts/open_xtts.mdx" /* webpackChunkName: "component---src-pages-blog-tts-open-xtts-mdx" */),
  "component---src-pages-blog-tts-prompt-to-voice-mdx": () => import("./../../../src/pages/blog/tts/prompt-to-voice.mdx" /* webpackChunkName: "component---src-pages-blog-tts-prompt-to-voice-mdx" */),
  "component---src-pages-blog-tts-solving-attention-problems-of-tts-models-with-double-decoder-consistency-mdx": () => import("./../../../src/pages/blog/tts/solving-attention-problems-of-tts-models-with-double-decoder-consistency.mdx" /* webpackChunkName: "component---src-pages-blog-tts-solving-attention-problems-of-tts-models-with-double-decoder-consistency-mdx" */),
  "component---src-pages-blog-tts-two-methods-for-better-attention-in-tacotron-mdx": () => import("./../../../src/pages/blog/tts/two-methods-for-better-attention-in-tacotron.mdx" /* webpackChunkName: "component---src-pages-blog-tts-two-methods-for-better-attention-in-tacotron-mdx" */),
  "component---src-pages-blog-tts-unleashing-creativity-mdx": () => import("./../../../src/pages/blog/tts/unleashing_creativity.mdx" /* webpackChunkName: "component---src-pages-blog-tts-unleashing-creativity-mdx" */),
  "component---src-pages-blog-tts-unleashing-emotion-with-xtts-mdx": () => import("./../../../src/pages/blog/tts/unleashing_emotion_with_xtts.mdx" /* webpackChunkName: "component---src-pages-blog-tts-unleashing-emotion-with-xtts-mdx" */),
  "component---src-pages-blog-tts-unleashing-soundscapes-mdx": () => import("./../../../src/pages/blog/tts/unleashing_soundscapes.mdx" /* webpackChunkName: "component---src-pages-blog-tts-unleashing-soundscapes-mdx" */),
  "component---src-pages-blog-tts-unleashing-the-power-of-xtts-mdx": () => import("./../../../src/pages/blog/tts/unleashing_the_power_of_xtts.mdx" /* webpackChunkName: "component---src-pages-blog-tts-unleashing-the-power-of-xtts-mdx" */),
  "component---src-pages-blog-tts-unleashing-your-vision-mdx": () => import("./../../../src/pages/blog/tts/unleashing_your_vision.mdx" /* webpackChunkName: "component---src-pages-blog-tts-unleashing-your-vision-mdx" */),
  "component---src-pages-blog-tts-voice-fusion-mdx": () => import("./../../../src/pages/blog/tts/voice-fusion.mdx" /* webpackChunkName: "component---src-pages-blog-tts-voice-fusion-mdx" */),
  "component---src-pages-blog-tts-xtts-taking-tts-to-the-next-level-mdx": () => import("./../../../src/pages/blog/tts/xtts_taking_tts_to_the_next_level.mdx" /* webpackChunkName: "component---src-pages-blog-tts-xtts-taking-tts-to-the-next-level-mdx" */),
  "component---src-pages-blog-tts-yourtts-zero-shot-text-synthesis-low-resource-languages-mdx": () => import("./../../../src/pages/blog/tts/yourtts-zero-shot-text-synthesis-low-resource-languages.mdx" /* webpackChunkName: "component---src-pages-blog-tts-yourtts-zero-shot-text-synthesis-low-resource-languages-mdx" */),
  "component---src-pages-brand-voices-tsx": () => import("./../../../src/pages/brand-voices.tsx" /* webpackChunkName: "component---src-pages-brand-voices-tsx" */),
  "component---src-pages-cpml-tsx": () => import("./../../../src/pages/cpml.tsx" /* webpackChunkName: "component---src-pages-cpml-tsx" */),
  "component---src-pages-dubbing-tsx": () => import("./../../../src/pages/dubbing.tsx" /* webpackChunkName: "component---src-pages-dubbing-tsx" */),
  "component---src-pages-editor-tsx": () => import("./../../../src/pages/editor.tsx" /* webpackChunkName: "component---src-pages-editor-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-how-it-works-tsx": () => import("./../../../src/pages/how-it-works.tsx" /* webpackChunkName: "component---src-pages-how-it-works-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-job-principal-full-stack-engineer-mdx": () => import("./../../../src/pages/job/principal-full-stack-engineer.mdx" /* webpackChunkName: "component---src-pages-job-principal-full-stack-engineer-mdx" */),
  "component---src-pages-job-renaissance-sales-representative-mdx": () => import("./../../../src/pages/job/renaissance-sales-representative.mdx" /* webpackChunkName: "component---src-pages-job-renaissance-sales-representative-mdx" */),
  "component---src-pages-job-senior-full-stack-engineer-mdx": () => import("./../../../src/pages/job/senior-full-stack-engineer.mdx" /* webpackChunkName: "component---src-pages-job-senior-full-stack-engineer-mdx" */),
  "component---src-pages-job-senior-ui-ux-engineer-mdx": () => import("./../../../src/pages/job/senior-ui+ux-engineer.mdx" /* webpackChunkName: "component---src-pages-job-senior-ui-ux-engineer-mdx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-newsletter-01-11-2021-mdx": () => import("./../../../src/pages/newsletter/01-11-2021.mdx" /* webpackChunkName: "component---src-pages-newsletter-01-11-2021-mdx" */),
  "component---src-pages-newsletter-02-05-2022-mdx": () => import("./../../../src/pages/newsletter/02-05-2022.mdx" /* webpackChunkName: "component---src-pages-newsletter-02-05-2022-mdx" */),
  "component---src-pages-newsletter-02-08-2021-mdx": () => import("./../../../src/pages/newsletter/02-08-2021.mdx" /* webpackChunkName: "component---src-pages-newsletter-02-08-2021-mdx" */),
  "component---src-pages-newsletter-03-01-2022-mdx": () => import("./../../../src/pages/newsletter/03-01-2022.mdx" /* webpackChunkName: "component---src-pages-newsletter-03-01-2022-mdx" */),
  "component---src-pages-newsletter-03-05-2021-mdx": () => import("./../../../src/pages/newsletter/03-05-2021.mdx" /* webpackChunkName: "component---src-pages-newsletter-03-05-2021-mdx" */),
  "component---src-pages-newsletter-04-04-2022-mdx": () => import("./../../../src/pages/newsletter/04-04-2022.mdx" /* webpackChunkName: "component---src-pages-newsletter-04-04-2022-mdx" */),
  "component---src-pages-newsletter-04-10-2021-mdx": () => import("./../../../src/pages/newsletter/04-10-2021.mdx" /* webpackChunkName: "component---src-pages-newsletter-04-10-2021-mdx" */),
  "component---src-pages-newsletter-05-04-2021-mdx": () => import("./../../../src/pages/newsletter/05-04-2021.mdx" /* webpackChunkName: "component---src-pages-newsletter-05-04-2021-mdx" */),
  "component---src-pages-newsletter-05-07-2021-mdx": () => import("./../../../src/pages/newsletter/05-07-2021.mdx" /* webpackChunkName: "component---src-pages-newsletter-05-07-2021-mdx" */),
  "component---src-pages-newsletter-06-06-2021-mdx": () => import("./../../../src/pages/newsletter/06-06-2021.mdx" /* webpackChunkName: "component---src-pages-newsletter-06-06-2021-mdx" */),
  "component---src-pages-newsletter-06-09-2021-mdx": () => import("./../../../src/pages/newsletter/06-09-2021.mdx" /* webpackChunkName: "component---src-pages-newsletter-06-09-2021-mdx" */),
  "component---src-pages-newsletter-06-12-2021-mdx": () => import("./../../../src/pages/newsletter/06-12-2021.mdx" /* webpackChunkName: "component---src-pages-newsletter-06-12-2021-mdx" */),
  "component---src-pages-newsletter-07-02-2022-mdx": () => import("./../../../src/pages/newsletter/07-02-2022.mdx" /* webpackChunkName: "component---src-pages-newsletter-07-02-2022-mdx" */),
  "component---src-pages-newsletter-07-03-2022-mdx": () => import("./../../../src/pages/newsletter/07-03-2022.mdx" /* webpackChunkName: "component---src-pages-newsletter-07-03-2022-mdx" */),
  "component---src-pages-post-production-tsx": () => import("./../../../src/pages/post-production.tsx" /* webpackChunkName: "component---src-pages-post-production-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-tos-tsx": () => import("./../../../src/pages/tos.tsx" /* webpackChunkName: "component---src-pages-tos-tsx" */),
  "component---src-pages-video-games-tsx": () => import("./../../../src/pages/video-games.tsx" /* webpackChunkName: "component---src-pages-video-games-tsx" */),
  "component---src-pages-voices-[id]-samples-tsx": () => import("./../../../src/pages/voices/[id]/samples.tsx" /* webpackChunkName: "component---src-pages-voices-[id]-samples-tsx" */),
  "component---src-pages-voices-[id]-synthesize-tsx": () => import("./../../../src/pages/voices/[id]/synthesize.tsx" /* webpackChunkName: "component---src-pages-voices-[id]-synthesize-tsx" */),
  "component---src-pages-voices-create-tsx": () => import("./../../../src/pages/voices/create.tsx" /* webpackChunkName: "component---src-pages-voices-create-tsx" */),
  "component---src-pages-voices-tsx": () => import("./../../../src/pages/voices.tsx" /* webpackChunkName: "component---src-pages-voices-tsx" */),
  "component---src-pages-xtts-tos-tsx": () => import("./../../../src/pages/xtts_tos.tsx" /* webpackChunkName: "component---src-pages-xtts-tos-tsx" */)
}

